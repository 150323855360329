@import '../../scss/_variables.scss';

.MuiList-root {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1C1B1F !important;
    font-size: 16px !important;
}

.MuiListItemButton-root:hover {
    background: rgba(204, 214, 231, 0.5) !important;
    border-radius: 8px !important;
    color: #1C1B1F !important;
}

.rutaActual {
    background: #CCD6E7 !important;
    border-radius: 8px !important;
    color: #003087 !important;
}