.bannerContent {
    //padding: 42px 42px 24px 42px;
    //gap: 43px;

    //header 96
    //body 8
    // banner title 64
    text-align: center;
    margin: 0;

    .banner {
        height: calc(100vh - 104px);
        box-sizing: border-box;
        text-align: left;
        max-width: 1000px;
        margin: auto;
        display: inline-block;

        & .bannerTitle {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #003087;
            height: 56px;
            margin: 30px;
            margin-bottom: 0px;
        }

        & .bannerImage {
            height: calc(100% - 86px);
            position: relative;
            box-sizing: border-box;
            display: inline-block;

            & img {
                width: auto;
                max-width: 100%;
                max-height: 100%;
                border-radius: 8px;
            }
        }
    }
}

.bannerImageText {
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    padding: 30px;
    width: 626px;
    height: auto;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 48px;
        color: #FFFFFF;
    }

    & p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #FEFCFC;
    }

}
