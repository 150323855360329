.btnBoxUpDownTemaTema {
    //background-color: aquamarine;
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    padding: 0;
    margin: 0;
    align-content: flex-start;
    justify-content: flex-end;

    & .btnUpDown {
        padding: 0;
        margin-top: 5px;
        height: 30px;
        color: #757575;
        width: auto;

        & :hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
            border-radius: 50%;
            color: #003087;
        }

        & .btnUpDownIco {
            font-size: 30px;
        }
    }
}