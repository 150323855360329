.textoContenidoEquivalencia {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    padding: 0;
}

.listadoEquivalencias {
    width: 167px;
    border-right: 2px solid #e9e9e9;
    padding-right: 45px;
}

.listadoEquivalencias h2 {
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 16px;
    color: #33599F;
    text-align: center;
}

.contenidoEquivalencia {
    width: 100%;
    margin-left: 50px;
}

.contenidoEquivalencia h2 {
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 16px;
    color: #33599F;
}

.equivalenciaHeader {
    margin-top: 20px;
    margin-bottom: 20px;
}

.equivalenciaHeaderTitle  {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #003087;
}

.equivalenciaHeaderTxt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}