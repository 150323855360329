/* .infoProyContainer {
    background-color: red !important;
    margin-top: 15px;
    height: 79px;
} */

.accSummaryInfoProy {
    border: none !important
}

.AccordionInfo {
    margin: 15px 0px;
    background-color: #FFF !important;
    padding-right: -10px !important;
    border: none !important;
    border-radius: 20px !important;
}

.cardTextContent {
    max-height: 30px;
}

.tituloInfoProy {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px !important;
    width: auto;
    height: 30px;
    color: #201A1B;
    margin-top: 10px;
    margin-bottom: 0px !important;
}

.infoDetalle h2 {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto';
    color: #201A1B;
}

.infoDetalle p {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #4D444C;
}

.chipLabelEquiv {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    margin-left: -10px !important;
    margin-right: 40px !important
}

.chipLabelEquiv label {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: auto;
}