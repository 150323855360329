.EnfAccordionSummary {
    padding: 24px 16px;
    gap: 16px;
    background: #E9E9E9;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #2D2926;
}

.Enfbadge {
    background: #FEFCFC;
    opacity: 0.76;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 6px 8px;
    gap: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #44464E;
}

.EnfbadgeIco {
    color: #33599F;
    font-size: 12px;
    line-height: 15px;
}

.EnfAccordionDetails {
    padding: 24px 16px;
    gap: 16px;
    background: rgba(233, 233, 233, 0.6);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.border-blue {
    border-bottom: 3px solid #B078D0;
}

.hr-ens {
    height: 3px;
    margin-top: 10px;
}

.hr-apr {
    height: 3px;
    background-color: #F1A46A;
    margin-top: 10px;
}

.hr-eva {
    height: 3px;
    background-color: #9BC479;
    margin-top: 10px;
}