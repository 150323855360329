.modalConfirmacionContainer {

    width: 412px;
    padding: 18px;
    border-radius: 28px !important;
}

.MuiDialog-paper {
    border-radius: 28px !important;
}

.modalConfirmacionHeader {
    display: flex;
    justify-content: center;
    flex-wrap: wrap !important;
}

.modalConfirmacionHeader p {
    text-align: left;
    padding: 10px;
}

.confirmTitle {
    font-family: 'Montserrat', 'Roboto' !important;
    font-weight: 700 !important;
    font-size: 18px;
    color: #2d2926;
}

.confirmText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2926;
}

.contenConfirmIcon {
    width: 100%;
    text-align: center;
}

.confirmIcon {
    font-size: 25px !important;
}

.modalConfirmacionContenido {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 8px;
    padding-top: 8px;
    padding-bottom: 35px;
}

.modalConfirmacionBotones {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 28px;
}