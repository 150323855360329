* {
	font-family: 'Montserrat', 'Roboto';
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
}

:root {
	--main-primary-blue: #003087;
	--main-primary-blue80: #33599f;
	--main-primary-blue60: #6683b7;
	--main-primary-blue40: #99accf;
	--main-primary-blue20: #ccd6e7;
	--main-primary-blue10: #ccd6e7;

	--main-secondary-blue: #00a4eb;
	--main-secondary-blue80: #2eb1ea;
	--main-secondary-blue60: #5cbee9;
	--main-secondary-blue40: #89cbe7;
	--main-secondary-blue20: #b7d8e6;

	--dark: #2d2926;
	--dark80: #626262;
	--dark60: #a9a9a9;
	--dark40: #cacaca;
	--dark20: #e9e9e9;
	--white: #fefcfc;

	--success: #358638;
	--warning: #ce9516;
	--error: #b1281d;
	--info: #0098ac;
	--acent: #601e6a;

	--gradient-first: #003087;
	--gradient-second: #0077c8;
}

/* ICONS */
.material-symbols-rounded {
	font-variation-settings: 'FILL' 1;
}

/* CLASES DE BOTONES */

.MuiButton-contained {
	background-color: #003087 !important;
	border-radius: 12px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	color: #fff !important;
}

.Mui-disabled {
	opacity: 0.7 !important;
}

.MuiButton-contained:hover {
	background-color: #33599f !important;
}

.MuiButton-outlinedError {
	border-radius: 12px !important;
}

.btn-primary {
	width: 280px;
	height: 50px;
	top: 20px;
	left: 15px;
	border-radius: 20px;
	padding: 16px, 87px, 16px, 87px;
	gap: 12px;
	background-color: var(--main-primary-blue);
	transition: all 300ms;
	outline: none;
	border: none;
	font-size: 16px;
	line-height: 19.5px;
	text-align: center;
	font-family: 'Monserrat', 'Roboto';
	margin-right: 10px;
}

.btn-secondary {
	width: 280px;
	height: 50px;
	top: 20px;
	left: 15px;
	border-radius: 20px;
	padding: 16px, 87px, 16px, 87px;
	gap: 12px;
	background-color: var(--dark80);
	transition: all 300ms;
	outline: none;
	border: none;
	font-size: 16px;
	line-height: 19.5px;
	text-align: center;
	font-family: 'Monserrat', 'Roboto';
}

.btn-sm {
	width: 153px;
	height: 50px;
	top: 20px;
	left: 20px;
	border-radius: 20px;
	padding: 16px, 48px, 16px, 48px;
	gap: 10px;
}

.btn-primary:hover {
	background: linear-gradient(
		160deg,
		var(--gradient-second) 0%,
		var(--gradient-first) 100%
	);
}

.btn-primary:focus {
	background-color: var(--main-primary-blue);
}

.btn-basic {
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
	white-space: pre;
	width: 280px;
	height: 50px;
	top: 300px;
	left: 15px;
	border-radius: 20px;
	padding: 16px 87px;
	gap: 12px;
	background-color: var(--white);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-outline-primary {
	background-color: #fff;
	border: 2px solid var(--main-primary-blue);
	color: var(--main-primary-blue);
	width: 280px;
	height: 50px;
	top: 90px;
	left: 15px;
	border-radius: 20px;
	padding: 18px, 87px, 18px, 87px;
	gap: 12px;
}

.btn-outline-primary:hover {
	background-color: var(--main-primary-blue20);
	color: var(--main-primary-blue);
	border: 2px solid var(--main-primary-blue);
}

.btn-outline-disabled {
	background-color: var(--white);
	color: var(--dark60);
	border: 2px solid var(--dark60);
	width: 280px;
	height: 50px;
	top: 90px;
	left: 15px;
	border-radius: 20px;
	padding: 18px, 87px, 18px, 87px;
	gap: 12px;
}

.btn-outline-disabled:hover {
	background-color: var(--white);
	color: var(--dark60);
	border: 2px solid var(--dark60);
	width: 280px;
	height: 50px;
	top: 90px;
	left: 15px;
	border-radius: 20px;
	padding: 18px, 87px, 18px, 87px;
	gap: 12px;
}

.btn-link {
	transition: all 300ms;
	width: 154px;
	height: 50px;
	top: 92px;
	left: 78px;
	color: var(--main-primary-blue);
	font-family: 'Montserrat', 'Roboto';
	font-size: 16px;
	border-radius: 20px;
	line-height: 20px;
	display: flex;
	align-items: center;
	line-height: 100%;
	text-align: center;
	justify-content: center;
	text-decoration: none;
	gap: 12px;
	cursor: pointer;
	padding: 16px, 24px, 16px, 24px !important;
}

.btn-link:hover {
	background-color: var(--main-primary-blue20);
}

.btn-link-sm {
	width: 145px;
	height: 28px;
	border-radius: 13px;
	padding: 6px 16px;
	background-color: var(--main-primary-blue20);
	outline: none;
	border: none;
	align-items: center;
	text-overflow: ellipsis;
	white-space: pre;
	font-family: 'Montserrat';
	font-size: 12px;
	line-height: 15px;
	color: var(--main-primary-blue);
	display: flex;
	justify-content: space-around;
	transition: all 300ms;
}

.btn-link-sm:hover {
	background-color: var(--main-primary-blue40);
}

.mas {
	align-items: center;
	text-overflow: ellipsis;
	white-space: pre;
	font-size: 15px;
	line-height: 15px;
	margin-right: 7px;
	color: var(--main-primary-blue);
}

/* END CLASES DE BOTONES */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* MUI CUSTOM PRESETS */
/* MUI CUSTOM PRESETS */

/* BUTTON */
.MuiButton-contained {
	background-color: #003087 !important;
	border-radius: 12px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	color: #fff !important;
	font-family: 'Roboto' !important;
}

.Mui-disabled {
	opacity: 0.7 !important;
}

.MuiButton-contained:hover {
	background-color: #33599f !important;
}

.MuiButton-outlined {
	font-family: 'Roboto' !important;
	border-radius: 12px !important;
}
/* BUTTON */

/* TEXTFIELD */
.Mui-disabled {
	background-color: rgba(233, 233, 233, 0.3) !important;
	color: #000 !important;
}

.MuiPopover-paper {
	border-radius: 18px !important;
}

.MuiMenuItem-root:hover {
	background-color: #ccd6e7 !important;
}

/* TEXTFIELD */

/* END MUI CUSTOM PRESETS */
/* END MUI CUSTOM PRESETS */

.contenedor {
	padding: 20px;
	margin-left: -140px;
	background: white;
	width: 1400px !important;
}
