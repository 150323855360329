@import '../../scss/variables';

.header .MuiAppBar-root {
    background: #FEFCFC;
    border-radius: 0px;
    height: 88px;
    box-shadow: none;
    filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.12));
}

.header .MuiTypography-h6 {
    font-family: 'Montserrat';
    font-Style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #343A40;
    margin: 0 10px;
}

.logoHeader {
    height: 67px;
    margin-left: 0;
    margin-top: 1px;
}

.fotoPerfil {
    height: 44px;
}

@media screen and (max-width: 767px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        max-width: 767px;
        width: 100%;
        height: 64px;
        padding: 14px 16px;
    }


    .respMenu {
        display: flex;
    }

    .respText {
        text-align: center;
        font-family: 'Montserrat', 'Roboto';
        font-style: bold;
        font-size: 16px;
        line-height: 19.5px;
        margin: auto;
        margin-left: 20px;
    }

    .arrowBack {
        display: flex;
        font-size: 25px;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 7px;
        color: $main-primary-blue;
    }


    .mail,
    .account-box,
    .lock {
        display: none;
    }

    .logo {
        display: none;
    }

    .fotoPerfil {
        width: 40px;
    }

    .opciones h2 {
        display: none;
    }

    .dropdown {
        display: none;
    }

    .indicador {
        display: block;
    }

    .menu {
        display: block;
        position: absolute;
        margin-top: -45px;
        margin-left: 40px;
    }

}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .menu {
        display: block;
        position: absolute;
        margin: auto;
        margin-top: -125px;
        margin-left: 40px;
    }

    .logo {
        display: none;
    }
}