.perfilContainer {
	width: 100%;
	margin: 20px;
	padding: 30px;
	display: flex;
	justify-content: space-between;
	border: 2px solid #dbdbdd;
	border-radius: 10px;
}

.inputLabel {
	font-size: 18px;
	font-weight: 500;
}

.inputCodigo {
	margin-bottom: 10px;
}

.perfilInfo {
	margin: 10px;
	margin-top: 20px;
}

.perfilNombre {
	font-size: 30px;
}

.perfilBadge {
	margin-right: 10px;
}

@media (max-width: 480px) {
	.perfilContainer {
		width: 87%;
		display: block;
	}
	.perfilNombre {
		margin-top: 20px;
		font-size: 25px;
	}
}

@media (max-width: 850px) {
    .perfilContainer {
        width: 93%;
        display: flex;
        justify-content: space-between;
    }
    .perfilInfo {
        margin-left: 70px;
    }
    .perfilNombre {
        font-size: 20px;
    }
}

@media (max-width: 980px) {
    .perfilContainer {
        width: 93%;
        display: flex;
        justify-content: space-between;
    }
    .perfilInfo {
        margin-left: 70px;
    }
    .perfilNombre {
        font-size: 20px;
    }
}