.MuiAccordion-root {
    background: none !important;
    border-radius: 12px !important;
    border: 1px solid #CACACA
}

.chipHorizontal {
    padding: 0 !important;
    margin-left: 10px !important;
}

.MuiPaper-root .Mui-expanded {
    border-radius: 0% !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.ModAccordion {
    border-radius: 12px;
    border: 1px solid #E9E9E9;
}

.ModAccordionSummary {
    background: #FFFFFF;
    border-radius: 12px;
    border-bottom: 1px solid #E9E9E9;
}

.ModAccordionDetails {
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: none;
}

.ModTitulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #003087;
    vertical-align: middle;
    word-wrap: break-word;
}

.ModTimeIcoText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D2926;
    vertical-align: top;
}

.ModTimeIco {
    font-size: 12px;
    line-height: 15px;
    color: #2D2926;
    margin-right: 10px;
    vertical-align: top;
}

.ModBitText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #2D2926;
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;
}

.ModBitIco {
    font-size: 16px;
    line-height: 15px;
    color: #33599F;
    margin-right: 5px;
    vertical-align: middle;
}

/* DropBox */

.ModDropDownToggle {
    width: auto;
    background: none;
    color: #2D2926;
    border: none;

    &:focus {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        color: #2D2926;
    }

    &:active {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }

    &:hover {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }
}

.ModDropdownMenu {
    padding: 8px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
}

.ModDropdownMenuItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2926;
    padding: 12px;
    vertical-align: middle;

    &:hover {
        background-color: #D9DEE7;
        color: #003087;
    }

}

/* Badge */

.Modbadge {
    background: #33599F;
    opacity: 0.76;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 6px 8px;
    gap: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFF;
}

.ModbadgeIco {
    color: #FFF;
    font-size: 12px;
    line-height: 15px;
}

.cardTextContentAcc {
    display: flex;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    color: #003087;
    transition: all 200ms;
    border: none;
}

.cardTextContentAcc:focus[contenteditable] {
    padding-left: 2px;
    border: 4px solid #003087 !important;
    border: none;
    padding-left: 6px;
    border-radius: 8px;
}

.chipLabelListado {
    display: flex;
    flex-wrap: wrap !important;
    width:120px !important;
    margin-left: -15px !important;
    margin-right: 10px !important
}

.chipLabelListado label {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: auto;
    text-align: center;
    margin-top: -5px !important;
}

.chipListadoMain {
    width: 130px;
}