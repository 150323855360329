.tableroGeneral {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
}

.textoContenidoTablero {
    display: flex;
    justify-content: space-between;
    padding: 25px 10px;
    margin: 5px
}

.tituloBotones {
    border-left: 1px solid #CCC;
}

.tableroIndividual {
    width: 346px;
    height: 100%;
    border-radius: 8px;
}

.tableroTitulo {
    padding-top: 8px;
    display: flex;
    justify-content: space-around;
}

.tableroTitulo h2 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin-top: 16px;
}

.tableroTimer {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin: 12px 5px;
}

.tableroContenido {
    padding: 10px;
}

.cardTablero {
    height: 80px;
    border-radius: 8px;
}

.cardDropDownToggle {
    width: auto;
    background: none;
    color: #2D2926;
    border: none;

    &:focus {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
    }

    &:active {
        border: none;
        background: none;
        outline: none;
    }
}

.cardDropdownMenu {
    padding: 8px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
}

.cardDropdownMenuItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2926;
    padding: 12px;

    &:hover {
        background-color: #D9DEE7;
        color: #003087;
    }
}

.proyectoHeader {

    width: 100%;
    background-color: #003087;
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    text-align: center;
    border-radius: 12px;
}


.proyectoHeader h2 {
    padding-top: 22px;
    padding-left: 5px;
    color: #FFF;
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 16px;
    width: 100%;
}

.proyectoTimer {
    display: flex;
    color: #FFFF;
}

.chipLabel {
    display: flex;
    flex-wrap: wrap !important;
    width:120px !important;
    margin-left: -15px !important;
    margin-right: 10px !important
}

.chipLabel label {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: auto;
    text-align: center;
    margin-top: -16px !important;
}

.chipTableroMain {
    margin-top: -10px;
    width: 120px !important;
}


.chipGeneral {
    /* margin-left: -8px !important; */
}

.chipVerde {
    margin-left: -8px !important;
    background: #DDF6DE !important;
    border: 1px solid #53D258 !important;
}

.chipAmarillo {
    margin-left: -8px !important;
    background: #FDF1D7 !important;
    border: 1px solid #FFB81C !important;
    color: #FFB81C !important;
}

.iconAmarillo {
    color: #FFB81C !important;
}

.chipRojo {
    margin-left: -8px !important;
   
    background: #F8D6D3 !important;
    border: 1px solid #DB3124 !important;
    color: #DB3124 !important;
}
.iconRojo {
    color: #DB3124 !important;
}

.tituloEdit {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: auto;
}

.tituloEdit:hover {
    margin: 3px 0px;
    padding-bottom: 5px !important;
    border: 2px solid #f7f7f7;
    border-radius: 8px;
}

.tituloEdit h2:hover {
    margin-top: -5px;
}

.tituloEdit:hover>*[contenteditable="true"] {
    //transition: all 300ms ease !important;
    border-radius: 8px;
    flex-wrap: nowrap !important;
    outline: none !important;
}

.tituloEdit:not(:focus)>*[contenteditable="true"] {
    outline: none;
}

.crearModuloBtn {
    margin: 10px 0px !important;
}

.seccionVolver {
    display: flex;
}

.seccionVolver h1 {
    margin-left: 10px;
    margin-top: 6px;
}