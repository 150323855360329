td[rowspan] {
    vertical-align: top;
}

.recursoTablaAcc {
    border: none;
}

.campoVisible {
    display: flex;
    font-size: 12px !important;
}

.campoDeshabilitar {
    display: flex;
    font-size: 12px !important;
}

.fuenteTablaRecurso {
    font-family: 'Montserrat', 'Roboto' !important;
}