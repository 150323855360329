.loadingBody {
    text-align: center;
}

.loading {
    width: 208px;
    animation: loader 1s linear infinite;
    background: none;
}

.loadTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #2D2926;
    padding: 48px 16px;
    gap: 24px;
}

.loadText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #2D2926;
    text-shadow: 2px 2px #FFF;
}

.bgLoadingWhite {
    background-color: #FEFCFC !important;
}

.bgLoadingWhiteTrans {
    background: rgba($color: #FEFCFC, $alpha: 0.7) !important;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}