.tableroHeaderEnfasis {
    display: flex;
    justify-content: space-around;
}

.tableroHeaderEnfasis h2 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    height: 20px;
    margin-top: 19px;
    margin-left: -18px;
}

.tableroTimerEnfasis {
    margin: 12px 0px;
}