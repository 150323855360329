.tablaTemaContainer {
    min-width: 1150px !important;
    margin-top: -20px;
    box-shadow: none !important;
    margin-bottom: -25px;
    border-radius: 12px !important;
}

.cardActionsContainer {
    display: flex !important;
}

.MuiMenu-list {
    overflow-y: hidden;
}

.cardActionsContainer .MuiTypography-h5 {
    margin-left: auto !important;
    font-family: 'Montserrat' !important;
    font-weight: 700;
    font-size: 16px !important;
    color: #003087 !important;
}

.cardChipsTema {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    & .cardTemaTools {
        width: auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }
}


.cardTemaContainer {
    border-radius: 12px !important;
    margin-top: 10px;
}

.cardTemaHeader {
    border: 1px solid #ccc !important;
    display: flex !important;
    flex-wrap: nowrap !important;
}


.chipCardTema {
    margin-left: 600px;
    position: absolute;
    margin-top: -8px;
}

.containerTemaMaterial {
    display: flex !important;
    justify-content: flex-start;
    position: absolute !important;
    margin-left: 330px;
    margin-top: -10px;
    flex-wrap: nowrap;
}

.cardTemaHeader p {
    position: absolute !important;
    margin-top: -13px !important;
    margin-left: 10px !important;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    color: #003087 !important;
}

.cardTemaContainerA {
    background-color: red !important;
}

.cardTema {
    margin-top: -13px !important;
    margin-bottom: -24px !important;
}

.btnBoxUpDownTema {
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    padding: 0;
    margin: 0;
    align-content: flex-start;

    & .btnUpDown {
        padding: 0;
        margin-top: 5px;
        height: 30px;
        color: #757575;
        width: auto;

        & :hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
            border-radius: 50%;
            color: #003087;
        }

        & .btnUpDownIco {
            font-size: 30px;
        }
    }

}
