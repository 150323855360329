@import '../../scss/variables';

.containerGeneral {
    height: 100%;
    padding: 0px;
    margin: 0;
    background-color: white;
}

.tituloContenido {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #003087;
}

.tituloActividad {
    font-size: 23px;
    margin-left: 18px;
}

.actividadContainer {
    display: flex;
    justify-content: space-between;
}

.cardActividad {
    background-color: $dark40;
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
}

.tituloHeader {
    font-size: 20px;
    font-weight: bold;
    flex-wrap: wrap;
}

.customBadges {
    margin-left: 20px;
    font-size: 14px;
}

.btn-primary {
    margin-right: 5px;
}

.cardContent {
    display: flex;
    justify-content: space-between;
}

.containerFooter {
    display: flex;
    justify-content: space-between;
}

.tituloEnfasis {
    font-size: 13px;
    margin-top: -4px;
    margin-bottom: 2px;
}

.cardModuloActividad {
    background-color: $white;
    padding: 20px;
    margin: 5px;
    border-radius: 8px;
}

.cardModulo {
    background-color: $dark60;
    margin:20px;
    border-radius: 8px;
    padding: 20px;
    height: auto;
}

.crearActiModulo {
    width: 140px;
    margin-top: -5px;
}

.contIcoPag {
    border-left: 1px solid #A9A9A9;
}

.icoPag {
    color: #626262;
    font-size: 24px;
    margin-left: 12px;
}

.icoPagActive {
    color: #003087;
    font-weight: 900;
}