.selectEnfasisAbm {
    width: 100% !important;
    margin-right: 4px !important;
}

.descripAbm {
    width: 100% !important;
}

.btnContentAbm {
    text-align: end;
    margin-top: 34px;
    padding: 0 20px;
}

.btnCrearAbm {
    color: #003087 !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    align-items: center !important;
    text-align: center !important;
    padding: 8px 16px 8px 8px !important;
    gap: 8px !important;
    width: auto !important;
    max-height: 40px !important;
    background: #CCD6E7 !important;
    border-radius: 13px !important;
    cursor: pointer;

    &:disabled {
        background: #E9E9E9 !important;
        border: none !important;
        color: #A9A9A9 !important;
    
        .btnIcoAbm {
            color: #A9A9A9 !important;
        }
    }
   
}

.btnAbmCancel {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    align-items: center !important;
    text-align: center !important;
    color: #44464E !important;
    padding: 8px 16px 8px 8px !important;
    gap: 8px !important;
    width: auto !important;
    max-height: 40px !important;
    background-color: white !important;
    border-radius: 13px !important;
    border: 1px solid #33599F !important;
    margin-left: 25px !important;

    &:disabled {
        background: #E9E9E9 !important;
        border: none !important;
        color: #A9A9A9;
    
        .btnIcoAbm {
            color: #A9A9A9 !important;
        }
    }
}

.btnIcoAbm {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    color: #33599F;
    margin-right: 5px;
}

.icoAbmEditar{
    text-decoration: none !important;
    color: #2D2926 !important;
    width: 18px !important;
    margin-right: 15px !important;

    &:hover {
        color: #003087 !important;
        cursor: pointer !important;
        background: none !important;
    }
}

.dataTableText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
}

.dataTableIco {
    margin-right: 10px;
}

.rdt_TableRow:hover {
    .dataTableText {
        color: #003087 !important;
    }
    
    background: #F6F5F5;
}

.dataTableContainer {
    overflow-x: hidden !important;
}

.abmCampoVisible {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #003087 !important;
    padding: 7px 16px 7px 8px;
    gap: 8px;
}

.abmCampoDeshabilitar {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D2926;    
    opacity: 0.38;
    padding: 7px 16px 7px 8px;
    gap: 8px;
    background: #CACACA;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
}

.abmIcoVisi {
    color: #003087 !important;
    line-height: 15px !important;
    vertical-align: middle;
    margin-right: 5px;
}

.abmIcoVisiOff {
    color: #2D2926 !important;
    line-height: 15px !important;
    vertical-align: middle;
    margin-right: 5px;
}