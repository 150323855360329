.refContainer {
    display: flex;
    margin-top: 50px;
    margin-bottom: -30px;
}

.refContainer p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #002117;
}