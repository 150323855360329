.cardAct {
    margin: 12px 10px;
    width: auto;
    border-radius: 12px;
}

.cardHeader {
    display: flex !important;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-radius: 12px !important;
    height: 67px;
    padding-top: 14px;
    padding-left: 15px;
}

.MuiCardHeader-title {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 15px !important;
    color: #2d2926;
    margin-top: 5px !important;
}

.cardActContainer .MuiPaper-root {
    border-radius: 12px !important;
}

.textoHeader {
    font-size: 'Roboto';
    font-weight: 400;
    font-size: '16px';
    line-height: 18.75px;
}

.textoSubHeader {
    margin-bottom: 10px;
}

.textoCard {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 16.4px;
}

.textoResaltado {
    background-color: #E4F4FC;
    color: #116791;
    padding: 0px 8px;
    width: auto;
    font-size: 12px;
    border-radius: 6px;
    margin-left: 10px;
    margin-top: 10px !important;
}

.MuiCardHeader-content {
    display: flex;
    justify-content: space-between;
}

.listadoMateriales {
    display: inline;
}

.listadoItems {
    margin: 5px 0px;
}

.cardHeader h2 {
    margin-top: 8px;
}

.cardTiempo {
    display: flex;
    margin-top: 5px;
}

.cardSubTitle {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    color: #2D2926;
}

.cardSubTitle {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    color: #2D2926;
}

.cardSubTitle:hover {
    cursor: pointer;
}

.cardAct .cardText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2926;
    text-transform: lowercase;
    padding: 20px 0px;
}

.cardeader {
    display: flex !important;
    background-color: #D9DEE7;
    width: 100%;
    height: auto;
    border: none;
    padding: 20px;
    justify-content: start;
}

.cardAct .cardBody {
    background-color: #FEFCFC;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}


/* ELEMENTS */

.cardAct .badge {
    background: #FEFCFC;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 6px 12px;
    text-align: center;
    opacity: 0.76;
    color: #44464E;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    gap: 8px;
    line-height: 15px;
}

.cardAct .badgeIco {
    color: #33599F;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    margin-right: 12px;
    vertical-align: text-bottom;
}

.cardAct .bitText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #2D2926;
    display: inline-block;
    margin-right: 45px;
    vertical-align: top;
}

.cardAct .bitIco {
    color: #6683B7;
    font-size: 14px;
    margin-right: 10px;
    vertical-align: top;
}

.cardAct .detalle {
    transform: rotate(90deg);
    float: right;
}

.cardAct .cardDropDownToggle {
    width: auto;
    background: none;
    color: #2D2926;
    border: none;

    &:focus {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
    }

    &:active {
        border: none;
        background: none;
        outline: none;
    }
}

.cardAct .cardDropdownMenu {
    padding: 8px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
}

.cardAct .cardDropdownMenuItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2926;
    padding: 12px;

    &:hover {
        background-color: #D9DEE7;
        color: #003087;
    }

}