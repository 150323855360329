.modalActividadContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1052px;
    height: auto;
    background-color: #FFF;
    border-radius: 12px;
}

.modalActividadHeader {
    display: flex !important;
    justify-content: space-between;
}

.modalActividadHeader h1 {
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 24px;
    color: #003087;
    margin: 25px 0px;
}

.ActiInputModal {
    width: 1001px;
    margin-top: 20px !important;
    border-radius: 6px !important;
}

.DescriInputModal {
    width: 1001px;
    margin-top: 14px !important;
}

.seccionInputAcitividad {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 8px;
}

.selectModuloActividad {
    width: 30% !important;
    margin-right: 4px !important;
}

.selectModuloTema {
    width: 70% !important;
    margin-left: 4px !important;
}

.selectEnfasisActividad {
    width: 30% !important;
    margin-right: 4px !important;
}

.selectTipoRecursoActividad {
    width: 70% !important;
    margin-left: 4px !important;
    margin-top: 0px !important;
}

.selectRecursoActividad {
    margin-top: 14px !important;
    width: 100% !important;
}

.selectMaterialActividad {
    width: auto !important;
    min-width: 130px !important;
    margin: 0 !important;
    margin-left: 1px !important;
    margin-top: 14px !important;
}

.selectMinutosActividad {
    width: 100% !important;
    margin-left: 8px !important;
    margin-top: 14px !important;
}

.selectPropositoActividad {
    width: 100% !important;
    margin-top: 14px !important;
}

.selectDificultadActividad {
    width: 100% !important;
    margin-top: 14px !important;
}

.modalSeccionBotones {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    display: flex !important;
    justify-content: end !important;
}

.chipBotonCrear {
    background: #CCD6E7 !important;
    margin-left: 4px !important;
    color: #003087 !important;
    width: 10% !important;
    height: 34px !important;
}

.chipBotonCancelar {
    height: 34px !important;
    color: #003087 !important;
    margin-left: 10px !important;
    border: 1px solid #003087 !important;
}

.underLabelActividad {
    font-size: 12px;
    font-family: 'Roboto', 'Montserrat';
}

.obligatorio {
    color: #D32F2F !important;
}

.containerProps {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 23px;
}

.textRed {
    color: #D32F2F !important;
}