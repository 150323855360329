@import '../../scss/_variables.scss';

.tituloContenido {
    font-family: 'Montserrat', 'Roboto';
    font-style: bold;
    font-size: 24px;
    color: #003087;
}

.borrar,
.editar,
.descargar {
    color: #003087;
    margin-right: 15px;
    cursor: pointer;
    text-decoration: none;
}

.borrar:hover,
.editar:hover,
.descargar:hover {
    color: $main-primary-blue60;
}

.masListado {
    align-items: center;
    text-overflow: ellipsis;
    white-space: pre;
    font-size: 15px;
    line-height: 15px;
    margin-right: -7px;
    color: var(--main-primary-blue);
}

.dataTableContainer {
    width: 100% !important;
    background-color: red !important;
}

.rdt_Pagination {
    width: 100% !important;
}

.sc-dkrFOg,
.iSAVrt,
.rdt_TableHeadRow {
    background-color: #e9e9e9 !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 14px;
}

.rdt_TableCell {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
}

.textoContenido {
    margin-top: 20px;
    margin-bottom: 20px;
}

.parrafoContenido {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    width: 959px;
}

.botonesContenido {
    display: flex;
    margin-bottom: 30px;
}

.Icono-activo {
    color: #2D2926;
    cursor: pointer;
    text-decoration: none;
    margin-right: 12px;
}

.Icono-activo:hover {
    color: #003087;
}

.Icono-inactivo {
    color: #2D2926;
    margin-right: 15px;
    cursor: pointer;
    text-decoration: none;
}

.buttonEliminarModal {
    color: #fff !important;
    width: 178px !important;
    background-Color: #003087 !important;
    margin-Top: -30px !important;
    margin: 4px !important;
    height: 43px !important;
    border-Radius: 20px !important;
}

.buttonEliminarModalOutline {
    width: 178px !important;
    /* margin-Top: -30px !important; */
    margin: 4px !important;
    height: 43px !important;
    border: 1px solid #CACACA !important;
    border-Radius: 40px !important;
    color: #626262 !important;
}

.contentDescarga {
    margin-left: -140px !important;
    margin-bottom: -20px !important;
    margin-top: -20px !important;
}

.rdt_TableCell {
    font-size: 16px !important;
    font-weight: 500;
}

.rdt_TableCell:hover {
    color: #003087 !important;
}