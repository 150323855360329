
.MenuDrawer .MuiDrawer-paper {
    margin-top: 88px !important;
    border-radius: 0px !important;
    background: #FEFCFC;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 23px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 24px 8px;
    gap: 16px;
    height: calc(100vh - 88px);
}

.MuiDrawer-root {
    background: none;
    margin-top: 88px !important;
}