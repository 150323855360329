.modalCrearProyecto {
    margin-top: 150px;
}

.crearProyectoHeader h1 {
    font-size: 24px;
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    color: #003087;
    margin: 25px 25px !important;
}

.crearProyectoHeader p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-bottom: 40px;
    margin-left: 49px !important;
}

.inputCrearProy {
    width: 100%;
    height: 48px !important;
    margin: 5px 0px !important;
}

.textAreaCrearProy {
    width: 100%;
    margin-top: 20px !important;
}

.seccionSelectProyecto {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}

.inputUnidProyecto {
    width: 590px !important;
    margin-right: 6px !important;
    height: 48px !important;
}

.inputCodProyecto {
    width: 400px !important;
    height: 48px !important;
}

.inputDuraProyecto {
    width: 300px !important;
    height: 48px !important;
}

.notiTitle {
    display: inline;
}

.notiTitle .iconNoti {
    margin: auto;
    text-align: center;
    margin: 30px;
}

.notiTitle h1 {
    font-size: 28px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat' !important;
    text-align: center;
    display: flex;
    justify-content: center;
}

.notiContent {
    display: flex;
    justify-content: center;
}

.notiChildren {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
}

.labelRadio {
    font-size: 18px !important;
    font-family: 'Roboto' !important;
    color: #2d2926 !important;
    text-align: left;
    margin-left: 5px;
    margin-top: -30px;
}

.notiModal {
    background-color: yellow;
}

.radioGrupo {
    margin-left: 20px;
    margin-top: 20px;
}

.botonesNoti {
    margin: 0px !important;
}