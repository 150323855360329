.cardEquivalenciaContainer {
    width: 120px;
    min-height: 94px;
    padding: 8px;
    border-radius: 12px !important;
    border: 1px solid #E9E9E9;

    & :hover {
        cursor: pointer;
    }

    &:hover .cardEquivalenciaTitulo p  {
        color: #003087;
    }

    &:hover .cardEquivalenciaTitulo span {
        color: #003087;
    }
}

.cardEquivActiva {
    width: 120px;
    height: 94px;
    padding: 8px;
    border-radius: 12px !important;
    border: 1px solid #003087;

    & .cardEquivalenciaTitulo p  {
        color: #003087;
    }

    & .cardEquivalenciaTitulo span {
        color: #003087;
    }
}

.cardEquivalenciaTitulo {
    width: 100%;
    margin: auto;
    padding: 0 5px !important;
    margin-top: 20px;
}

.cardEquivalenciaTitulo p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
}

.cardEquivalenciaTitulo span {
    color: #2D2926;
    font-size: 20px;
    font-weight: 500;
}
