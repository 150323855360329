.filterCrearModulo {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #003087;
    padding: 0;
    margin: 0;

    & span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #A9A9A9;
    }
}

.filterCrearModuloContent {
    border-top: 2px solid #E9E9E9;
    width: 100%;
    padding-top: 10px;
}