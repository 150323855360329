.proyectoHeader {
    width: 100%;
    background-color: #003087;
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
    text-align: center;
    border-radius: 12px;
}


.proyectoHeader h2 {
    padding-top: 22px;
    padding-left: 5px;
    color: #FFF;
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 16px;
}

.proyectoTimer {
    display: flex;
    color: #FFFF;
}

.tituloEdit {
    display: flex;
}

/* DropBox */

.ProyDropdownMenu {
    padding: 8px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
}

.ProyDropdownMenuItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    color: #2D2926;
    padding: 12px;
    vertical-align: middle;

    &:hover {
        background-color: #D9DEE7;
        color: #003087;
    }

}

.chipHorizontal {
    padding: 0 !important;
    margin-left: 10px !important;
}