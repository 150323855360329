.notiSuccessFilled {
    background: #358638 !important;
    border-radius: 4px !important;
}

.notiSuccessFilled .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    background: #358638 !important;
    padding: 6px 16px !important;
}

.notiDangerFilled {
    background: #DB3124 !important;
    border-radius: 4px !important;
}

.notiDangerFilled .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    background: #DB3124 !important;
    padding: 6px 16px !important;
}

.notiWarningFilled {
    background: #CE9516 !important;
    border-radius: 4px !important;
}

.notiWarningFilled .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    background: #CE9516 !important;
    padding: 6px 16px !important;
}

.notiInfoFilled {
    background: #0098AC !important;
    border-radius: 4px !important;
}

.notiInfoFilled .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    background: #0098AC !important;
    padding: 6px 16px !important;
}


// OUTLINE

.notiSuccessOutline {
    background: #FFF !important;
    border-radius: 4px !important;
    border: 1px solid #358638 !important;
}

.notiSuccessOutline .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2D2926 !important;
    background: rgba(83, 210, 88, 0.05) !important;
    padding: 6px 16px !important;
}

.notiDangerOutline {
    background: #FFF !important;
    border-radius: 4px !important;
    border: 1px solid #B1281D;
}

.notiDangerOutline .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2D2926 !important;
    background: rgba(219, 49, 36, 0.05) !important;
    padding: 6px 16px !important;
}

.notiWarningOutline {
    background: #FFF !important;
    border-radius: 4px !important;
    border: 1px solid #CE9516 !important;
}

.notiWarningOutline .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2D2926 !important;
    background: rgba(255, 184, 28, 0.05) !important;
    padding: 6px 16px !important;
}

.notiInfoOutline {
    background: #FFF !important;
    border-radius: 4px !important;
    border: 1px solid #0098AC !important;
}

.notiInfoOutline .MuiPaper-root {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2D2926 !important;
    background: rgba(0, 188, 212, 0.05) !important;
    padding: 6px 16px !important;
}