.containerCombo {
    display: flex;
    justify-content: space-around;
}

.comboAsociar {
    width: 100%;
    height: 51px !important;
    margin: 0px 3px;
}

.inputAsociar input {
    height: 18px !important;
}

.formAsociarInput {
    width: 32.33%;

}


.formAsociar {
    width: 32.33%;
}

.chipAsociar {
    width: 104px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserrat', 'Roboto';
    background-color: #ccd6e7;
}

.containerChipAsociar {
    display: flex !important;
    justify-content: right !important;
    flex-wrap: nowrap;
    margin-right: 5px;
}