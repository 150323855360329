.ActAccordionSummary {
    background: #FFFFFF;
    border-radius: 12px;
}

.ActAccordionDetails {
    padding: 16px;
    background: #FFFFFF;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid #99ACCF;
}

.ActTitulo {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #2D2926;
    text-transform: lowercase;
    padding: 20px 0px;
}

.ActTituloIco {
    font-size: 16px;
    color: #B11276;
}

.ActTituloDeta {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #116791;
    background: rgba(118, 199, 239, 0.2);
    border-radius: 4px;
    padding: 6px 16px;
    gap: 8px;
    width: auto;
    display: inline-block;
    margin-left: 30px;
}

.ActTimeIcoText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D2926;
    vertical-align: top;
}

.ActTimeIco {
    font-size: 12px;
    line-height: 15px;
    color: #2D2926;
    margin-right: 10px;
    vertical-align: top;
}

.ActBitText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #2D2926;
    display: inline-block;
    margin-right: 45px;
    vertical-align: top;
}

.ActBitIco {
    color: #6683B7;
    font-size: 14px;
    margin-right: 10px;
    vertical-align: top;
}

/* DropBox */

.ActDropDownToggle {
    width: auto;
    background: none;
    color: #2D2926;
    border: none;

    &:focus {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        color: #2D2926;
    }

    &:active {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }

    &:hover {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }
}

.ActDropdownMenu {
    width: 146px;
    padding: 8px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
}

.ActDropdownMenuItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 49px;
    color: #2D2926;
    padding: 12px;

    &:hover {
        background-color: #D9DEE7;
        color: #003087;
    }

}