.contenidoEquivCont {
    margin-top: 50px;
}

.contenidoEquivalenciaHeader {
    display: flex;
    margin-left: 20px;
}

.contenidoEquivalenciaHeader h2 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
    color: #003087;
}

.contenidoEquivalenciaHeader p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    color: #2D2926;
    margin-left: 120px;
}

.dataTableContainerEquiv {
    width: 100% !important;
    border-radius: 12px;
}

.tablaContenidoEqui {
    font-weight: 700;
    color: #2D2926;
    font-family: 'Montserrat';
    font-size: 13px;
}

.editarFactorCampo {
    border-radius: 8px;
    margin: auto !important;
    height: 25px;
    padding-top: 2px;
    text-align: center !important;
    width: 54px;
    background-color: #D9DEE7;
}

.editarFactorCampo:focus {
    background-color: #D9DEE7;
    border: none;
    outline: none;
}

.editarFactorCampo:hover>*[contenteditable="true"] {
    outline: none !important;
    border: none;
}

.redprueba {
    border: 2px solid red !important;
}