$defultFontSize: 16px;
$primary-color: #0e5c7a;
$primary-color-hover: #063c51;
$defaultFontSize: 16px;

$menu_color: #252527;

$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #000;
$font-dark-ligth: #252527;
$font-dark: #000;

$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark-ligth: #252527;
$border-dark: #000;

$background-light: #fff;
$background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #595959;
$background-dark-ligth: #252527;
$background-dark: #000;
$background-success: #84b84c1f;
$background-error: #ff00001f;

$success: #84b84c;
$error: #f00;

// Social Color
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;
/* Breakpoints estandarizados:

Móviles: entre 320 y 480 píxeles.
Tablets: entre 768 y 1024 píxeles.
Pantallas grandes: más de 1200 píxeles. */

$media-breackpoint-down-xs: 413.98px;
$media-breackpoint-down-sm: 573.98px;
$media-breackpoint-down-md: 767.98px;
$media-breackpoint-down-lg: 991.98px;
$media-breackpoint-down-xl: 1199.98px;

$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 768px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1200px;


/* COLORES PRINCIPALES */

$main-primary-blue: #003087;
$main-primary-blue80: #33599f;
$main-primary-blue60: #6683b7;
$main-primary-blue40: #99accf;
$main-primary-blue20: #ccd6e7;
$main-primary-blue10: #ccd6e7;

$main-secondary-blue: #00a4eb;
$main-secondary-blue80: #2eb1ea;
$main-secondary-blue60: #5cbee9;
$main-secondary-blue40: #89cbe7;
$main-secondary-blue20: #b7d8e6;

$dark: #2d2926;
$dark80: #626262;
$dark60: #a9a9a9;
$dark40: #cacaca;
$dark20: #e9e9e9;
$white: #fefcfc;

$success: #358638;
$warning: #ce9516;
$error: #b1281d;
$info: #0098ac;
$acent: #601e6a;

$gradient-first: #003087;
$gradient-second: #0077c8;

/* END COLORES PRINCIPALES */