.modalActividadContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1052px !important;
    height: auto;
    background-color: #FFF;
    border-radius: 12px;
}

.modalActividadHeader {
    display: flex !important;
    justify-content: space-around;
}

.modalActividadHeader h1 {
    font-family: 'Montserrat', 'Roboto';
    font-weight: 700;
    font-size: 24px;
    color: #003087;
    margin: 25px 0px;
}

.ActiInputModal {
    width: 1801px;
    margin-top: 20px !important;
    border-radius: 6px !important;
}

.DescriInputModal {
    width: 1001px;
    margin-left: 0px !important;
    margin-top: 14px !important;
}

.seccionInputAcitividad {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 14px !important;
}

.seccionInputMatAcitividad {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between !important;
    padding: 0 23px !important;
}

.selectModuloActividad {
    width: 267px !important;
}

.selectModuloTema {
    width: 70% !important;
    margin-left: 4px !important;
}

.selectEnfasisActividad {
    width: 269px !important;
}

.selectTipoRecursoActividad {
    margin-top: 14px !important;
    width: 448px !important;
}

.selectRecursoActividad {
    margin-top: 14px !important;
    margin-bottom: 4px !important;
    width: 100% !important;
}

.selectMaterialActividad {
    width: 350px !important;
    margin: 0px 3px !important;
}

.selectMateDinamicActividad {
    width: 350px !important;
    margin: 4px 3px 0px 6px !important;
    display: flex;
}

.selectMatUniActividad {
    width: 200px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.selectPropositoActividad {
    width: 100% !important;
    margin: 0px 8px !important;
}

.selectDificultadActividad {
    width: 100% !important;
}

.modalSeccionBotones {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
    display: flex !important;
    justify-content: end !important;
}

.selectMaterialesContainer {
    display: flex;
    justify-content: space-between;
    background-color: red;
}

.chipBotonCrear {
    background: #CCD6E7 !important;
    margin-left: 4px !important;
    color: #003087 !important;
    width: 10% !important;
    height: 34px !important;
}

.chipBotonCrearCopia {
    background: #CCD6E7 !important;
    margin-left: 4px !important;
    color: #003087 !important;
    width: 136px !important;
    height: 34px !important;
}

.chipBotonCancelar {
    height: 34px !important;
    color: #003087 !important;
    margin-left: 10px !important;
    border: 1px solid #003087 !important;
}

.underLabelActividad {
    font-size: 12px;
    font-family: 'Roboto', 'Montserrat';
}

.obligatorio {
    color: #D32F2F !important;
}

.headerTiempoTotal {
    display: flex !important;
    justify-content: end !important;
}

.tiempoTotalHeader {
    display: flex;
}

.tiempoTotalHeader h2 {
    margin-top: 9px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    margin: 9px 10px;
}