.recursoItem {
    height: 48px;
    background-color: #e9e9e9;
}

.recursoHeader {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.recursoHeader h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    padding-top: 13px;
    padding-left: 10px;
    color: #2D2926;
}

.enfasisHeader h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    padding-top: 13px;
    padding-left: 12px;
    color: #003087;
}

.accordionHeaderRecurso {
    background-color: #FFF !important;
    border: none !important;
    outline: none;
    height: 64px !important;
    display: flex !important;
    width: 120%;
    justify-content: space-between !important;
}

.tablaRecursoHeader th {
    font-size: 12px !important;
    background-color: #FEFCFC !important;
    text-align: left;
    height: 34px !important;
    border: none !important;
}

.tablaRecursoContenido td,
th {
    border: none !important;
    background-color: #F6F5F5;
}