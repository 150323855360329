.seccionRecurso {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.selectEnfasisRecurso {
    width: 1100px !important;
    margin-right: 10px !important;
}

.selectTipoRecurso {
    width: 1400px !important;
    margin-right: 10px !important;
}

.inputRecurso {
    width: 550px !important;
}

.estadoRecurso {
    padding-top: 8px;
    width: 1320px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
}

.seccionBotonesRecurso {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

.chipGuardar {
    color: #003087 !important;
    height: 34px !important;
    background: #CCD6E7 !important;
}

.chipCancelar {
    height: 34px !important;
    margin-left: 8px !important;
    color: #44464E !important;
}