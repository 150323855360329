.cardPro {
    border: none;
}

.cardPro .cardHeader {
    background-color: #FFFFFF;
    padding: 16px;
}

.cardPro .cardTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #003087;
}

.cardPro .cardHeaderIco {
    color: #CACACA;
    font-size: 18px;
    margin-right: 12px;
    vertical-align: text-bottom;
}

.cardPro .cardHeaderTxt {
    font-size: 12px;
    color: #626262;
    vertical-align: text-bottom;
}

.tableroGeneral {
    display: flex;
}

.tableroSeccion {
    width: 100%;
    border-radius: 8px;
    margin: 12px;
    border: 2px solid #E9E9E999;
    background-color: #E9E9E999;
}

.tableroHeader {
    display: flex;
    background-color: #E9E9E999;
    justify-content: space-between;
    height: 70px;
    border-top-left-radius: 8px;
    padding-top: 8px;
    border-top-right-radius: 8px;
}

.tableroHeader h2 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-weight: 700;
    margin-top: 16px;
    margin-left: 30px
}

.lineaHrEval {
    border: 2px solid #9BC479;
    background-color: #9BC479;
    color: #9BC479;
    background: #9BC479;
    opacity: 100;
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
}

.lineaHrApren {
    opacity: 100;
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
}

.lineaHrEnse {
    border: 2px solid #B078D0;
    background-color: #B078D0;
    color: #B078D0;
    background: #B078D0;
    opacity: 100;
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
}

.tableroTimer {
    display: flex;
    margin-right: 56px;
    justify-content: space-between;
}

.tableroContenido {
    padding: 0px 10px;
}

.cardDescri {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    width: 100%;
    color: #003087;
    margin-top: 10px;
    margin-left: 20px;
}

.cardTitulo {
    font-family: 'Montserrat' !important;
    font-weight: 700 !important;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 4px !important;
    color: #003087;
}

.cardTimer {
    background-color: #33599F;
    border-radius: 8px;
    display: flex;
    width: 35%;
    height: auto;
    padding: 2px;
    justify-content: space-around;
    margin-bottom: 10px;
}

.chipLabelModulo {
    display: flex;
    flex-wrap: wrap;
    width: 180px;
    margin-left: -70px !important;
}

.chipLabelModulo label {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Roboto';
    text-align: center;
    margin: auto;
}

.chipModulo {
    margin-bottom: -20px;
}

.summaryHeader {
    background: rgb(255, 255, 255) !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(138, 190, 224, 0.6) 100%) !important;
}

.summaryHeaderEmpt {
    background: #Fff !important;
    border-bottom: 1px solid #CACACA !important;
}