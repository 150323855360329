.subtituloContenido {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: #33599F;
    margin-top: 60px;
    margin-bottom: 40px;
}

.inputMaterial {
    width: 657px;
    height: 48px;
    padding: 0px 12px;
    border: 2px solid #d6d6d6;
}

.compoInput {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

input[type="checkbox"] {
    margin-top: 10px;
    -webkit-appearance: none;
    -moz-appearence: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

input[type="checkbox"]:focus {
    outline: 0;
}

.campoForm {
    display: flex;
    width: 95%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.deshacer {
    margin-top: 1px;
    font-size: 19px;
}

.toggle {
    height: 25px;
    width: 48px;
    border-radius: 30px;
    display: inline-block;
    position: relative;
    margin: 0;
    border: 2px solid #a9a9a9;
    background: linear-gradient(180deg, #a9a9a9 0%, #a9a9a9 100%);
    transition: all .2s ease;
}

.toggle:after {
    content: '';
    position: absolute;
    top: 0.09px;
    left: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}

.toggle:checked {
    border-color: #6683b7;
    background: linear-gradient(180deg, #6683b7 0%, #6683b7 100%);
    transition: all .2s ease;
}

.toggle:checked:after {
    transform: translateX(22px);
}


.seccionBoton {
    width: 100%;
    float: right;
    align-items: right;
    display: flex;
    justify-content: end;
}

.botonCrear {
    width: auto !important;
    height: 34px;
    border-radius: 13px;
    padding: 8px 16px;
    background-color: #CCD6E7;
    color: #003087;
    font-size: 14px;
    margin-left: 20px;
    display: flex;
}

.botonCrear:hover {
    background-color: #33599F;
    color: #FFFFFF;
}

.botonDeshacer {
    background-color: #fff;
    border: 2px solid var(--main-primary-blue);
    color: #33599F;
    width: 104px !important;
    height: 34px;
    border-radius: 13px;
    padding: 5px 16px 20px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    
}

.botonDeshacer:hover {
    background-color: #FFF;
    color: #003087
}

.mas {
    align-items: center;
    text-overflow: ellipsis;
    white-space: pre;
    font-size: 14px;
    line-height: 20px;
    margin-right: -5px;
    margin-left: auto;
    margin-bottom: 40px;
    color: #003087;
}

.listado {
    margin-top: 35px;
    border-top: 1px solid #CaCaCa;
    padding-top: 40px;
}

.seccionInput {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.seccionCheck {
    display: flex;
    flex-wrap: wrap;
}

.seccionCheck label {
    font-size: 12px;
    position: absolute;
    font-family: 'Roboto';
    text-align: left;
    color: #626262;
}

.seccionCheck span {
    margin-top: 17px;
    color: #626262;
}

.campoVisible {
    color: #003087;
    font-size: 16px;
    text-align: center;
    padding: 7px 16px;
}

.visible {
    font-size: 18px;
    margin-right: 10px;
}

.campoDeshabilitar {
    color: #626262;
    font-size: 16px;
    background-color: #CACACA;
    padding: 7px 16px;
    border-radius: 13px;
}

.deshabilitar {
    font-size: 18px;
    margin-right: 10px;
}

.campoVisible {
    color: #003087;
    font-size: 16px;
    text-align: center;
    padding: 7px 16px;
}

.visible {
    font-size: 18px;
    margin-right: 10px;
}

.campoDeshabilitar {
    color: #626262;
    font-size: 16px;
    background-color: #CACACA;
    padding: 7px 16px;
    border-radius: 13px;
}

.deshabilitar {
    font-size: 18px;
    margin-right: 10px;
}