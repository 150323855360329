.cardMod {
    border: 1px solid #E9E9E9;
    border-radius: 8px;
}

.accordionSummaryModulo {
    height: 75px !important;
    border-bottom: 1px solid #CACACA !important;
}

.cardMod .cardModTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4D;
}

.cardMod .cardHeader {
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    gap: 16px;
    width: 100%;
    background: #FFFFFF;
    border: none;
    display: block;
}

.cardMod .cardHeaderHr {
    margin-top: 10px;
    height: 1px;
    width: 100%;
    background-color: #A9A9A9;
}

.cardMod .cardHeaderIco {
    color: #A9A9A9;
    font-size: 14px;
    margin-right: 12px;
}

.cardMod .cardHeaderTxt {
    font-size: 12px;
    color: #626262;
}


.tableroGeneral {
    display: flex;
}

.tableroSeccion {
    width: 100%;
    border-radius: 8px;
    margin: 8px;
    height: 100%;

}

.tableroHeader {
    display: flex;
    justify-content: space-around;
}

.tableroHeader h2 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    height: 20px;
    margin-top: 19px;
    margin-left: -18px;
}

.tableroTimer {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin: 12px 12px;
    margin-left: 30px;
}

.cardTitulo {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    width: 100%;
    margin-top: 8px;
    color: #003087;
}


.cardDescriModulo {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    width: 100%;
    color: #003087;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap,
}

.cardDescriModu:focus[contenteditable] {
    padding: 10px 15px !important;
    height: 36px;
    text-overflow: clip !important;
    margin-top: 10px;
    margin-left: 4px;
}

.cardTextContent {
    display: flex;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px !important;
    color: #003087;
    margin-top: 5px;
    transition: all 200ms;
}


.cardTextContent .IconButton {
    margin-bottom: 5px;
    margin-left: 10px;
}

.cardTextContent {
    border: none;
}

.cardTextContent:focus[contenteditable] {
    padding-left: 2px;
    border: 4px solid #003087 !important;
    border: none;
    padding-top: 4px;
    padding-left: 6px;
    border-radius: 8px;
    margin-top: -6px;
}

.cardTimer {
    background-color: #33599F;
    border-radius: 12px;
    display: flex;
    width: 35%;
    font-size: 14px;
    height: 30px;
    justify-content: space-around;
}

.icon {
    font-size: 24px !important;
    color: #FFF;
    padding-top: 2px;
}

.cardTimer span {
    color: #FFF;
    margin-top: 2px;
}

.cardButton {
    margin-top: -3px !important;
    color: #626262 !important;
    margin-left: 10px !important;
}

.cardOpciones {
    width: 30%;
    margin-top: 10px;
    display: flex;
    justify-content: right;
}

.MuiMenu-list MenuItem {
    position: absolute !important;
}

.Accordion {
    margin: 15px 0px;
    background-color: #FFF !important;
    padding-right: -10px !important;
}

.MuiAccordionSummary-root {
    border-radius: 8px !important;
}

.cardSeccion {
    display: flex;
    justify-content: right;
    align-items: right;

    & .MuiIconButton-root {
        transform: translateY(-6px);
    }

}

.botonToggle {
    width: auto;
    background-color: #f7f7f7;
    margin-bottom: -20px;
    margin-top: -34px;
    margin-left: -30px;
    border-radius: 100%;
}

.botonToggle:hover {
    background-color: #ccc;
}


.chipLabelModulo {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin-left: -10px !important;
    margin-right: 40px !important
}

.chipLabelModulo label {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: auto;
}

.chipModulo {
    margin-top: -10px;
}

.botonEditarActividad {
    width: 94%;
    height: 49px;
    background-color: #FFF;
    margin: 13px 0px;
    padding: 8px 0px;
    padding-left: 15px;
    cursor: pointer;
    margin-left: 10px !important;
    border-radius: 12px;
}

.botonEditarActividad:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.botonEditarChip {
    cursor: pointer !important;
}

.ActDropDownToggleModulo {
    width: auto;
    background: none;
    color: #2D2926;
    border: none;
    margin-top: -11px;
    margin-right: 14px;

    &:focus {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        color: #2D2926;
    }

    &:active {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }

    &:hover {
        border: none;
        background: none;
        outline: none;
        color: #2D2926;
    }
}

.btnBoxUpDown {
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    padding: 0;
    margin: 0 5px;
    align-content: flex-start;

    & .btnUpDown {
        padding: 0;
        height: 30px;
        color: #757575;
        width: auto;

        & :hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
            border-radius: 50%;
            color: #003087;
        }

        & .btnUpDownIco {
            font-size: 30px;
        }
    }

    // & .MuiButtonBase-root:disabled {
    //     color: red !important;
    //   }

}

